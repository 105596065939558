// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","formErrors":"formErrors_fc0d86e","splitSection":"splitSection_cba7c0c","splitSectionWithErrors":"splitSectionWithErrors_b5c3031","splitSectionLeft":"splitSectionLeft_fb84c11","splitSectionRight":"splitSectionRight_fa14cdc","formSectionNoPadding":"formSectionNoPadding_c36f97b","formSectionSinglePreview":"formSectionSinglePreview_f37f155","paymentMethods":"paymentMethods_d6d3c9e","multiSelectRadio":"multiSelectRadio_c692282","pin":"pin_bd7a029","title":"title_dba74e0","pinSelected":"pinSelected_cd8d5ae","addOnTitle":"addOnTitle_e69f0cc","tooltip":"tooltip_b7f1fc3","tooltipDescriptions":"tooltipDescriptions_abdfe33","addOnDescription":"addOnDescription_f8d2260","addOnPrice":"addOnPrice_a02f874","pickupLocation":"pickupLocation_e523e3c","confirmPickupLocation":"confirmPickupLocation_d1b1582"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"formErrors\":\"formErrors_fc0d86e\",\"splitSection\":\"splitSection_cba7c0c\",\"splitSectionWithErrors\":\"splitSectionWithErrors_b5c3031\",\"splitSectionLeft\":\"splitSectionLeft_fb84c11\",\"splitSectionRight\":\"splitSectionRight_fa14cdc\",\"formSectionNoPadding\":\"formSectionNoPadding_c36f97b\",\"formSectionSinglePreview\":\"formSectionSinglePreview_f37f155\",\"paymentMethods\":\"paymentMethods_d6d3c9e\",\"multiSelectRadio\":\"multiSelectRadio_c692282\",\"pin\":\"pin_bd7a029\",\"title\":\"title_dba74e0\",\"pinSelected\":\"pinSelected_cd8d5ae\",\"addOnTitle\":\"addOnTitle_e69f0cc\",\"tooltip\":\"tooltip_b7f1fc3\",\"tooltipDescriptions\":\"tooltipDescriptions_abdfe33\",\"addOnDescription\":\"addOnDescription_f8d2260\",\"addOnPrice\":\"addOnPrice_a02f874\",\"pickupLocation\":\"pickupLocation_e523e3c\",\"confirmPickupLocation\":\"confirmPickupLocation_d1b1582\"}";
        // 1734152742494
        var cssReload = require("../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  